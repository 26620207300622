#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.bg {
    position: absolute;
    top: -4rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 100vh;
    
}

.introContent {
    height: 100vh;
    width: 100vh;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 4rem;
    margin-left: 6rem;
}

.hello {
    font-size: 3rem;
    font-weight: 100;
    font-family: 'Roboto Mono';
}

.introText {
    font-family: 'Roboto Mono';
}
.introName {
    color: rgb(173, 43, 69);
    font-family: 'Roboto Mono';
}

.introPara {
    margin-top: 0.5rem;
    font-size: 1.45rem;
    font-weight: 100;
    font-family: 'Cormorant Garamond';
}

.btn {
    background: black;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    margin-top: 3.5rem;
    font-family: 'Roboto Mono';
    cursor: pointer;
}

@media screen and (max-width:840px) {
    .bg {
        right: -10vw;
    }
    .introContent {
        font-size: 10vw;
    }
    .hello {
        font-size: 4.5vw;
    }
}