.column {
    float: left;
  }
  
  .left {
    width: 28%;
    position: absolute;
    left: 0;
  }
  
  .right {
    width: 65%;
    position: absolute;
    right: 0;
  }

.about-text > p{
      font-size:14px;
      color:rgba(0,0,0,.6);
      line-height:1.8em;
      margin-right: 4.3rem;
      margin-top: 1.5rem;
      font-family: 'Cormorant Garamond';
  }
  .about-text{
      margin-bottom:25px;
  }
  .about-btns > .btn-custom{
      margin-right:25px;
      font-family: 'Roboto Mono';
  }
  a.btn-custom{
      color:#FFF;
      text-decoration:none;
  }
  .btn-custom{
      color:#FFF;
      letter-spacing:4px;
      text-transform:uppercase;
      text-decoration:none;
      text-align:center;
      font-size:11px;
      font-weight:400;
      display:inline-block;
      line-height:1em;
      padding:17px 32px;
      position:relative;
      z-index:5;
      background:transparent;
      border:none;
  }
  .btn-custom:after{
      content: ' ';
      position:absolute;
      top:0;
      left:-10px;
      right:-10px;
      bottom:0;
      -webkit-transform:rotate(-4deg);
      -moz-transform:rotate(-4deg);
      -ms-transform:rotate(-4deg);
      -o-transform:rotate(-4deg);
      transform:rotate(-4deg);
      z-index:-1;
      opacity:0;
      visibility:hidden;
      background:rgba(0,0,0,.3);
      -webkit-transition:all .3s cubic-bezier(.785,.135,.15,.86);
      -moz-transition:all .3s cubic-bezier(.785,.135,.15,.86);
      transition:all .3s cubic-bezier(.785,.135,.15,.86);
  }
  .btn-custom:before{
      content: ' ';
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index:2;
      border:solid 1px #FFF;
      border-color:rgba(255,255,255,.8);
  }
  .btn-custom:hover:after{
      opacity:1;
      visibility:visible;
  }
  .btn-custom.btn-color:after{
      background:#AD2B45;
  }
  .btn-custom.btn-color:hover:after{
      opacity:.4;
  }
  .btn-custom.btn-color{
      color:#202020;
  }
  .btn-custom.btn-color > span:before,
  .btn-custom.btn-color > span:after{
      background:rgba(0,0,0,.2);
  }
  .btn-custom.btn-color:before{
      border-color:rgba(0,0,0,.2);
  }
  .info-list{
      padding:1rem;
      margin:0;
      list-style:none;
      margin-top: 1rem;
      margin-left: 4rem;
  }
  .info-list > li{
      padding:14px 0;
      border-bottom:solid 1px rgba(0,0,0,.1);
      font-family: 'Cormorant Garamond';
  }
  .info-list > li:last-of-type{
      border:none;
  }
  .info-list > li > strong{
      letter-spacing:2px;
      color:#202020;
      text-transform:uppercase;
      font-family: 'Roboto Mono';
      font-size: small;
  }
  .info-list > li > span{
      color:rgba(0,0,0,.6);
  }
  
  .bg-grey .fa {
    color: #ffffff;
  }
  